<!-- Categories Start -->
<div class="container-xxl py-5 category">
    <div class="container">
      <!-- Title Section -->
      <div class="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 class="section-title bg-white text-center px-3">{{ 'Home.Categories' | translate }}</h6>
        <h1 class="mb-5">{{ 'Home.CourseCategories' | translate }}</h1>
      </div>
  
      <!-- Search Bar -->
      <div class="row justify-content-center mb-5">
        <div class="col-lg-6 col-md-8 col-sm-12">
          <div class="search-bar">
            <input
              type="text"
              class="form-control"
              placeholder="{{ 'Home.SearchPlaceholder' | translate }}"
              [(ngModel)]="searchTerm"
              (input)="filterCategories()"
            />
            <i class="fas fa-search search-icon"></i>
          </div>
        </div>
      </div>
  
      <!-- Categories Grid -->
      <div class="row g-4 catTitle">
        <div
          *ngFor="let category of filteredCategories"
          class="col-lg-3 col-md-4 col-sm-6 wow zoomIn"
          data-wow-delay="0.1s"
        >
          <a
            class="category-card position-relative d-block overflow-hidden"
            [routerLink]="'/courses/category/' + category._id"
          >
            <img
              class="img-fluid"
              [src]="category.image || 'assets/img/default-category.jpg'"
              [alt]="category.name"
            />
            <div class="category-info bg-white text-center position-absolute bottom-0 end-0 py-3 px-4">
              <h5 class="m-0">{{ category.name }}</h5>
              <small class="text-muted">{{ getLen(category.courses) || 0 }} {{ 'Home.courses' | translate }}</small>
            </div>
          </a>
        </div>
      </div>
  
      <!-- No categories found message -->
      <div *ngIf="filteredCategories.length === 0" class="text-center mt-4">
        <p class="text-muted">{{ 'Home.NoCategoriesFound' | translate }}</p>
      </div>
    </div>
  </div>
  <!-- Categories End -->